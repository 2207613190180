<template>
  <section >
    <h2 class="titulo-links-escolha">EDITAR PROFESSOR</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="flex md3 sm6 xs12">
                    <va-input

                      disabled
                      type="number"
                      v-model="professor.ano"
                      placeholder="Informe o ano"
                    />
                  </div>
                  <div class="flex md4 xs12">
                    <va-select
                      :label="$t('Selecione o professor')"
                      textBy="nome"
                      track-by="id"
                      v-model="professorSelecionado"
                      :options="professorALL"
                    />
                  </div>
                  <div class="flex">
                    <va-button class="mr-2 mb-2"  @click="Editar"> {{ $t('Editar') }}</va-button>
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>

import { Professor } from "@/class/professor.js";
import { Calendario } from "@/class/calendario.js";
import { Servidores } from "@/class/servidores.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
    id:{
    },
  },
  components: {

  },
  name: 'Professor',
  data () {
    return {
      professor: {
        servidor_id: null,
        ano: null,
        user_id: store_token_info.usuario_logado,
      },
      professorSelecionado: null,
      professorALL: [],
    }
  },
  methods: {
    clear (field) {
      this[field] = ''
    },
    async editarOption(){
        try {
          let data = await Servidores.obtemUm(this.id);

          this.professorSelecionado = data.data;
        } catch (e) {
          // console.log(e);
        }
    },
    async buscarCalendarioAtual() {
      let data = await Calendario.calendarioAtual();
      this.professor.ano = data.data.ano;
    },
    async buscarServidoresALL() {
      let data = await Professor.obtemTodosAnoLetivo( this.professor.ano);
      for (const el of data.data) {
          if (!el.situacao)
            continue;
          let data1 = await Servidores.obtemUm(el.servidor_id);
          let novo = {
            id: el.servidor_id,
            nome: data1.data[0].nome,
          }
          this.professorALL.push(novo);
      };
    },
    async Editar() {
      try {
        this.professor.servidor_id = this.professorSelecionado.id;
        this.professor.turma_id = this.turmaSelecionada.id;
        const data = await Professor.cadastrar(this.professor);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
      catch(e) {
        alert(e);
        this.$vaToast.init({
          message: "Erro ao editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  async mounted() {
    if (this.id == null) {
      this.$router.push({name: 'professoresall'});
    }
    else {
      await this.buscarCalendarioAtual();
      this.buscarServidoresALL();
      this.editarOption();
    }
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
